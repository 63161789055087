import { useEffect, useState } from 'react';
import { ThemeProvider, Theme, StyledEngineProvider, createTheme, adaptV4Theme } from '@mui/material';
import DateFnsUtils from '@date-io/date-fns';
import { useDispatch } from 'react-redux';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { NotificationContainer } from 'react-notifications';
import { IntlProvider } from 'react-intl';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../assets/vendors/style';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import AppLayout from './AppLayout';
import { useTypedSelector } from '../reducers';
import customTheme from './themes/customTheme';
import { loadCampaigns } from '../reducers/Campaign/campaignSlice';
import { keycloak } from '../keycloak';
import { AppLocale } from '../lngProvider';
import QueryContextProvider from '../contexts/QueryContextProvider';
import LoadingProvider from '../contexts/LoadingContextProvider';
import Loader from '../components/Loader';
import { ErrorBoundary } from 'react-error-boundary';
import Error404 from '../routes/common-routes/404';
import { Navigate, useLocation } from 'react-router-dom';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const App = (): JSX.Element => {
  const dispatch = useDispatch();
  const { locale } = useTypedSelector(({ settings }) => settings);
  const [width] = useState('100%');
  const [initURL, setInitURL] = useState<string>('/tff/order/search');
  const location = useLocation();

  useEffect(() => {
    if (!initURL) {
      setInitURL(location.pathname);
    }
  }, [dispatch, initURL, location.pathname, location.search]);

  useEffect(() => {
    console.log('**** Fetching initial Data');
    dispatch(loadCampaigns());
  }, []);

  useEffect(() => {
    if (keycloak.token) {
      // console.log('useEffect for KC Token', keycloak.token);
      // @ts-ignore
      if (!window.ENV?.STAGE || !keycloak.hasRealmRole('SY_' + window.ENV?.STAGE)) {
        // @ts-ignore
        toast.error(`You're not allowed to access this environment. ${window.ENV?.STAGE}`, {
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          onClose: () => {
            keycloak.logout();
          },
        });
      }
    }
  }, [keycloak.token]);

  if (location.pathname === '/') {
    if (initURL === '' || initURL === '/') {
      return <Navigate to={'/tff/order/search'} />;
    } else {
      return <Navigate to={initURL} />;
    }
  }

  const eventLogger = (event: unknown, error: unknown) => {
    // console.log('onKeycloakEvent', event, error);
  };

  const tokenLogger = (tokens: unknown) => {
    // console.log('onKeycloakTokens', tokens);
  };

  const applyTheme = createTheme(adaptV4Theme(customTheme));
  const currentAppLocale = AppLocale[locale.locale];

  return (
    <ReactKeycloakProvider authClient={keycloak} onEvent={eventLogger} onTokens={tokenLogger}>
      <LoadingProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={applyTheme}>
            <QueryContextProvider>
              <IntlProvider key={'locale'} locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <ErrorBoundary fallback={<Error404 />}>
                    <div className="app-main" style={{ width, margin: 'auto', paddingBottom: '100px' }}>
                      <Loader />
                      <AppLayout />
                      <ToastContainer
                        style={{ width: '800px', fontWeight: 'normal', fontSize: '14px' }}
                        position={'top-center'}
                      />
                      <NotificationContainer />
                    </div>
                  </ErrorBoundary>
                </MuiPickersUtilsProvider>
              </IntlProvider>
            </QueryContextProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </LoadingProvider>
    </ReactKeycloakProvider>
  );
};

export default App;
