import {
  Typography,
  Box,
  Container,
  TextField,
  useMediaQuery,
  Checkbox,
  FormControlLabel,
  FormControl,
} from '@mui/material';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useRebook } from './RebookContext';
import { useIntl } from 'react-intl';

import { TFF } from '@tff/types';

import { FlightLand, FlightTakeoff } from '@mui/icons-material';
import { Fare } from '@tff/types/TFF/tff-flight-details';

interface props {
  orderDetails: TFF.OrderDetails;
  journey: TFF.Journey;
  airports: TFF.IAirport[];
}

const RebookEditSegments: React.FC<props> = ({ orderDetails, journey, airports }) => {
  const segments: TFF.Segment[] = journey.Segments;
  const journeyType = journey.Ond === orderDetails.Journeys[0].Ond ? 'Outbound' : 'Return';
  const [departure, setDeparture] = useState<string>(format(new Date(segments[0].DepartureTime), 'yyyy-MM-dd'));
  const [origin, setOrigin] = useState<string>(segments[0].Origin);
  const [destination, setDestination] = useState<string>(segments[segments.length - 1].Destination);
  const [restrictions, setRestrictions] = useState<boolean | string>(false);

  const changeWidth = useMediaQuery('(max-width: 1240px)');
  const intl = useIntl();
  const { toggleSearchButton, toggleSearchState, toggleDefaultValues } = useRebook();

  const handleRestrictions = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRestrictions(event.target.checked);
  };

  const handleOrigin = (e: string) => {
    setOrigin(e);
    toggleSearchButton(true);
  };

  const handleDeparture = (e: string) => {
    setDeparture(e);
    toggleSearchButton(true);
  };

  const handleDestination = (e: string) => {
    setDestination(e);
    toggleSearchButton(true);
  };

  const handlePassangers = () => {
    return Object.values(orderDetails.Passengers).map(passenger => passenger.Type);
  };

  const countTypes = (types: string[]) => {
    const counts: { [key: string]: number } = {};

    for (const type of types) {
      counts[type] = (counts[type] || 0) + 1;
    }

    return counts;
  };

  const { INF = 0, ADT = 0, CHD = 0 } = countTypes(handlePassangers());

  useEffect(() => {
    const currentCarrierCode = segments[0].MarketingCarrier;
    const currentFareBase = journey.FareBasis ?? 'UNKNOWN';
    const fareProductsIndex = journeyType === 'Outbound' ? 0 : 1;
    let fareAmount = 0;

    if (orderDetails.Fares) {
      const paxFares: Fare[] = Object.values(orderDetails.Fares);
      paxFares.forEach(f => {
        fareAmount += f.FareProducts[fareProductsIndex].BaseAmount ?? 0;
      });
    }
    const newTravelDetails = [
      departure,
      origin,
      destination,
      `${INF}`,
      `${ADT}`,
      `${CHD}`,
      currentCarrierCode,
      currentFareBase,
      `${fareAmount}`,
      `${restrictions}`,
    ];
    toggleSearchState(journeyType, newTravelDetails);
  }, [departure, origin, destination, restrictions]);

  return (
    <Container
      sx={{
        padding: 3,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box sx={{}}>
        <Box>
          <Typography sx={{ color: '#737373', marginLeft: 4, fontSize: '16px' }}>
            {intl.formatMessage({ id: 'rebook.Origin' })}
          </Typography>
        </Box>
        <Box>
          <FlightTakeoff sx={{ marginTop: '5px' }} />
          <TextField
            variant="standard"
            sx={{
              width: {
                xs: '50px',
                sm: '140px',
                md: changeWidth ? '180px' : '220px',
                lg: '220px',
              },
              marginLeft: 1,
              backgroundColor: '#F3F0EC',
            }}
            value={origin}
            onChange={e => handleOrigin(e.target.value)}
          />
        </Box>
      </Box>
      <Box>
        <Box>
          <Typography sx={{ color: '#737373', fontSize: '16px' }}>
            {intl.formatMessage({ id: 'rebook.Departure' })}
          </Typography>
        </Box>
        <TextField
          type="date"
          variant="standard"
          sx={{
            width: {
              xs: '50px',
              sm: '140px',
              md: changeWidth ? '180px' : '220px',
              lg: '220px',
            },
            backgroundColor: '#F3F0EC',
          }}
          value={departure}
          onChange={e => handleDeparture(e.target.value)}
        />
      </Box>
      <Box>
        <Box>
          <Typography sx={{ color: '#737373', marginLeft: 4, fontSize: '16px' }}>
            {intl.formatMessage({ id: 'rebook.Destination' })}
          </Typography>
        </Box>
        <Box>
          <FlightLand sx={{ marginTop: '5px' }} />
          <TextField
            variant="standard"
            sx={{
              width: {
                xs: '50px',
                sm: '140px',
                md: changeWidth ? '180px' : '220px',
                lg: '220px',
              },
              backgroundColor: '#F3F0EC',
              border: 'none',
              marginLeft: 1,
            }}
            value={destination}
            onChange={e => handleDestination(e.target.value)}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: '15%',
        }}
      >
        <FormControl>
          <FormControlLabel
            label={intl.formatMessage({ id: 'rebook.Restrictions' })}
            control={<Checkbox onChange={handleRestrictions} />}
          />
        </FormControl>
      </Box>
    </Container>
  );
};

export default RebookEditSegments;
