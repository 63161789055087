import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { IntlMessages } from '../util';

export interface customDialogIProps {
  onConfirm?: () => void;
  onCancel?: () => void;
  children: React.ReactNode;
  title?: React.ReactNode;
  initialOpen: boolean;
  width?: 'md' | 'lg' | 'xs';
  form?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmButton?: boolean;
  transparent?: boolean;
  closeButton?: boolean;
  warning?: boolean;
  closeOnBackdropClick?: boolean;
  titleBg?: string;
  confirmButtonStyle?: React.CSSProperties;
  cancelButtonStyle?: React.CSSProperties;
  confirmDisabled?: boolean;
}

const CustomDialog: React.FC<customDialogIProps> = ({
  onConfirm,
  onCancel,
  children,
  title,
  form = false,
  width,
  initialOpen,
  confirmButtonText,
  cancelButtonText,
  confirmButton = true,
  closeButton = true,
  warning = false,
  closeOnBackdropClick = false,
  transparent,
  titleBg,
  confirmButtonStyle = {},
  cancelButtonStyle = {},
  confirmDisabled = false,
}) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(initialOpen);
  }, [initialOpen]);

  const handleConfirmation = () => {
    onConfirm && onConfirm();
    setOpen(false);
  };

  const handleClose = () => {
    onCancel && onCancel();
    setOpen(false);
  };

  const handleCloseDialog = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>, reason: string) => {
    if (reason && reason === 'backdropClick' && !closeOnBackdropClick) return;
    onCancel && onCancel();
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        maxWidth={width}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: transparent ? 'transparent' : 'white',
            boxShadow: 'none',
            overflow: 'hidden',
          },
        }}
      >
        {title && (
          <DialogTitle
            id="alert-dialog-title"
            style={{
              backgroundColor: warning ? 'rgb(255, 244, 229)' : titleBg ? titleBg : 'white',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
              {warning && <WarningIcon style={{ marginRight: '5px', color: '#ff9800' }} />}
              {title}
            </div>
          </DialogTitle>
        )}
        <DialogContent>{children}</DialogContent>
        {!form && (
          <DialogActions>
            {closeButton && (
              <Button onClick={handleClose} color="secondary" style={cancelButtonStyle}>
                {cancelButtonText ?? <IntlMessages id="global.cancel" />}
              </Button>
            )}
            {confirmButton && (
              <Button
                onClick={handleConfirmation}
                variant="contained"
                color="primary"
                style={confirmButtonStyle}
                disabled={confirmDisabled}
                autoFocus
              >
                {confirmButtonText ?? <IntlMessages id="global.confirm" />}
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

export default CustomDialog;
