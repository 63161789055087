import * as React from 'react';
import { useEffect, useState, useMemo } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import {
  Button,
  Collapse,
  TextField,
  Theme,
  IconButton,
  Typography,
  Table,
  Card,
  CardContent,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Radio,
  Box,
} from '@mui/material/';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { Alert, AlertTitle } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import BookingDetailHeader from './BookingDetailHeader';
import PassengerDetailCard from './Passenger/PassengerDetailCard';
import PricingDetailCard from './PricingDetailCard';
import CustomDialog from './Dialogs';
import FlightDetailCard from './FlightDetailCard';
import RebookFlightCard, { SelectedAmendBookings } from './RebookFlight/RebookFlightCard/RebookFlightCard';
import { RebookProvider } from './RebookFlight/RebookContext';
import PassengersEditForm from './Passenger/PassengersEditForm';
import ContactsDetailCard from './Contacts/ContactsDetailCard';

import { ISourceConfig, Message } from '../models';
import { useTypedSelector } from '../reducers';
import { IntlMessages } from '../util';

import { filterActiveFee } from '../routes/settings/FeeConfigs/filter-active-fee';
import CancelBooking from './CancelBooking/CancelBooking';
import ChangeBooking from './ChangeBooking/ChangeBooking';
import { useRetrieveAirports } from '../hooks/use-retrieve-airports';
import FlightChanges from './FlightChanges/FlightChanges';

import * as TFF from '@tff/types/TFF';
import ChargesSummary from './ChargesSummary';
import { MessagesProps, useMessages } from '../hooks/use-messages';
import ReactJson from 'react-json-view';
import { CheckDifferences } from '../util/check-differences';
import { useConfirmBooking } from '../hooks/use-confirm-booking';
import { ConfirmBookingRequest } from '@tff/types/TFF/confirm-booking-handler';
import { toast } from 'react-toastify';
import { FeeAndTax } from '@tff/types/TFF/tff-flight-details';

import { useApigeeCall } from '../hooks/MakeApigeeCall';
import JsonFileUploader from './JsonFileUploader';
import sortAndNormalize from '../util/sortAndNormalizePassengers';
import { TypeOfNdcBookingSource } from '@tff/types/NDC';
import { useLocation } from 'react-router-dom';
import { RerunInvoluntaryChangeProps, useRerunInvoluntaryChange } from '../hooks/use-rerun-involuntary-change';
import AncillariesCard from './AncillariesCard/AncillariesCard';
import ServicesCard from './servicePackages/ServicesCard';
import AncillariesPage from './AncillariesPage/AncillariesPage';
import { useFetchServices } from '../hooks/use-service-packages';

const StyledTableCell = withStyles(() =>
  createStyles({
    root: {
      height: 15,
      padding: 5,
    },
    head: {
      backgroundColor: '#70CBF4',
      color: '#092A5E',
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

interface IProps {
  sourceConfig?: ISourceConfig;
  flightDetails: TFF.FlightDetails;
  midocoFetchedOrder?: TFF.FlightDetails;
  midocoOrderNo?: number;
  retrieveItem?: TFF.Retrieve;
}

type PaxWithPhoneAndEmail = TFF.Passenger & { PhoneNumber?: string; Email?: string };

const FlightDetails: React.FC<IProps> = ({
  flightDetails: givenFlightDetails,
  sourceConfig,
  midocoFetchedOrder,
  midocoOrderNo,
  retrieveItem,
}) => {
  const intl = useIntl();

  const { keycloak } = useKeycloak();

  const location = useLocation();

  const [flightDetails, setFlightDetails] = useState<TFF.FlightDetails>(givenFlightDetails);

  const orderDetails = flightDetails?.OrderDetails as TFF.OrderDetails;
  const orderSummary = flightDetails?.OrderSummary as TFF.OrderSummary;

  //product has no support for cancel and name or contact change actions
  const changeActionsDisabled = retrieveItem?.product === 'aircruiser';

  // transform passengers to match required types
  const originPassengers = orderDetails?.Passengers && sortAndNormalize(orderDetails.Passengers);

  const [passengers, setPassengers] = useState<PaxWithPhoneAndEmail[]>([...originPassengers]);

  const [addresses, setAddresses] = useState<TFF.Address[]>();
  const { campaigns } = useTypedSelector(({ campaignData }) => campaignData);

  const [activeFlightMenuButton, setActiveFlightMenuButton] = React.useState<string>('');

  const [displayCancelFlight, setDisplayCancelFlight] = useState<boolean>(false);
  const [displayChangeBooking, setDisplayChangeBooking] = useState<boolean>(false);
  const [openNoticeDialog, setOpenNoticeDialog] = useState<boolean>(false);
  const [displayRebookFlight, setDisplayRebookFlight] = useState<boolean>(false);
  const [displayAddAncillaries, setDisplayAddAncillaries] = useState<boolean>(false);
  const [openAddNoticeDialog, setOpenAddNoticeDialog] = useState<boolean>(false);
  const [openHistoryDialog, setOpenHistoryDialog] = useState<boolean>(false);
  const [openRebookDialog, setOpenRebookDialog] = useState<boolean>(false);
  const [displayFlightChanges, setDisplayFlightChanges] = useState<boolean>(false);
  const [displayEditPassengers, setDisplayEditPassengers] = useState<boolean>(false);
  const [displayACRTriggerDialog, setDisplayACRTriggerDialog] = useState<boolean>(false);
  const [selectedFee, setSelectedFee] = useState<TFF.IFee | undefined>();
  const [airports, setAirports] = useState<TFF.IAirport[]>();
  const [openHint, setOpenHint] = useState<boolean>(false);
  const [disableButtons, setDisableButtons] = useState<boolean>(changeActionsDisabled);
  const [hasFlightChanges, setHasFlightChanges] = useState<boolean>(false);
  const [displayPricingDetails, setDisplayPricingDetails] = useState<boolean>(false);
  const [notice, setNotice] = useState<string>();
  const [messageRequest, setMessageRequest] = useState<MessagesProps | undefined>(undefined);
  const { messageServiceItems, addMessage } = useMessages(messageRequest);
  const { confirmError, confirmStatus, confirmResponse, setConfirmBookingRequest } = useConfirmBooking();
  const [recordLocator, setRecordLocator] = useState<string>();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [successMsg, setSuccessMsg] = useState<string | null>(null);
  const [uploadedJsonFlight, setUploadedJsonFlight] = useState<TFF.FlightDetails>();
  const [openCancelFlightDialog, setOpenCancelFlightDialog] = useState<boolean>(false);
  const [selectedJourneysToCancel, setSelectedJourneysToCancel] = useState<TFF.Journey[]>([]);
  const [rerunInvRequest, setRerunInvRequest] = useState<RerunInvoluntaryChangeProps | undefined>();
  const [selectedAmendBookings, setSelectedAmendBookings] = useState<SelectedAmendBookings>({
    combiHashCodes: [],
    journeyONDs: [],
    offerPrices: [],
  });
  const [servicePackageFee, setServicePackageFee] = useState<number>(0);
  // INFORMATION: Added eslint disable for next hook because giving warnings while developing.
  // Please remove it when you want to develop this feature.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { rerunInvoluntaryChangeError, rerunInvoluntaryChangeStatus, rerunInvoluntaryChangeFetchedResult } =
    useRerunInvoluntaryChange(rerunInvRequest!);

  const { setServiceRequest, serviceStatus, serviceResponse } = useFetchServices();

  useEffect(() => {
    setServiceRequest(retrieveItem?.spBookingId ?? retrieveItem?.tfmPnr);
  }, [retrieveItem]);

  useEffect(() => {
    if (serviceResponse) {
      let passengers = serviceResponse?.servicePackages.passengers!;
      setServicePackageFee(passengers.length * passengers[0].selectedServicePackage.price.value);
    } else {
      setServicePackageFee(0);
    }
  }, [serviceStatus]);

  useApigeeCall({
    endpoint: `/external-order-notification/${retrieveItem?.product}/${flightDetails?.OrderSummary?.DesignatedAirlineCode}/${retrieveItem?.recordLocator}/${retrieveItem?.tfmPnr}`,
    call: displayACRTriggerDialog,
    onSuccess: () => {
      setDisplayACRTriggerDialog(false);
      setSuccessMsg('PNR changed triggered, successfully at ACR.');
    },
    onError: er => {
      setErrorMsg(String(er));
      setDisplayACRTriggerDialog(false);
    },
  });

  const useStyle = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        [theme.breakpoints.down('md')]: {
          width: '95%',
        },
        [theme.breakpoints.up('md')]: {
          width: '85%',
        },
        [theme.breakpoints.up('lg')]: {
          width: '60%',
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        margin: '0 auto',
      },
      backIconWrapper: {
        height: '36px',
        width: '36px',
        borderRadius: '18px',
        border: '2px solid #092A5E',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      flexRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '20px 0 20px 0',
        alignSelf: 'self-start',
      },
      table: {
        minWidth: 650,
      },
      paymentTableRoot: {
        marginBottom: '20px',
      },
      chargesSummaryHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
      },
      menuRoot: {
        top: 70,
        transition: 'top 0.5s ease-in',
      },
      menuRootShrunk: {
        top: 0,
        transition: 'top 1s ease-out',
      },
      formControlLabel: {
        padding: 10,
        border: '1px solid grey',
        borderBottom: 'none',
      },
    }),
  );

  const classes = useStyle();

  const { error, status, fetchedAirports } = useRetrieveAirports({
    OndList: orderSummary?.Airports,
  });
  const [isShrunk, setShrunk] = useState(false);

  const checkUnconfirmedSegments = () => {
    flightDetails?.OrderDetails.Journeys.forEach(j => {
      if (
        j.AdditionalParams &&
        Object.keys(j.AdditionalParams).find(ap => ap.startsWith(`TFF_ALERT:UNCONFIRMED_STATE`))
      ) {
        setOpenHint(true);
      }
    });
  };

  const totalTuiFeeAmount: number = useMemo(() => {
    if (!midocoFetchedOrder) {
      return 0;
    }

    let totalFeeAmount: number = 0;
    Object.values(midocoFetchedOrder?.OrderDetails?.Fares!).forEach((f: TFF.Fare) => {
      const serviceFee: FeeAndTax | undefined = f.FeesAndTaxes?.find(
        (ft: FeeAndTax) => ['Serviceentgelt Ticket', 'ServiceFee', 'telefonische Buchung'].indexOf(ft.Code!) > -1,
      );
      if (serviceFee) {
        totalFeeAmount += serviceFee.Amount ?? 0;
      }
    });
    return totalFeeAmount;
  }, [midocoFetchedOrder]);

  useEffect(() => {
    const onScroll = () => {
      setShrunk(isShrunk => {
        if (!isShrunk && (document.body.scrollTop > 120 || document.documentElement.scrollTop > 120)) {
          return true;
        }

        if (isShrunk && document.body.scrollTop < 2 && document.documentElement.scrollTop < 2) {
          return false;
        }

        return isShrunk;
      });
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    if (!error && status === 'FETCHED' && fetchedAirports) {
      setAirports(fetchedAirports);
    }
  }, [fetchedAirports, error, status]);

  useEffect(() => {
    if (!orderDetails) {
      return;
    }
    checkUnconfirmedSegments();

    if (!displayFlightChanges && location.hash.indexOf('#') > -1) {
      switch (location.hash) {
        case '#changes':
          handleMenuClicked('changes');
          break;
        case '#rebook':
          handleMenuClicked('rebook');
          break;
        case '#cancel':
          handleMenuClicked('cancelBooking');
          break;
        case '#passengerChanges':
          handleMenuClicked('passengerChanges');
          break;
        case '#pricingDetails':
          handleMenuClicked('pricingDetails');
          break;
        default:
          break;
      }
    } else if (!displayCancelFlight && location.hash.indexOf('#cancelBooking') > -1) {
      handleMenuClicked('cancelBooking');
    }

    setRecordLocator(orderSummary?.RecordLocator);
    setMessageRequest({ recordLocator: orderSummary?.RecordLocator, bookingSource: sourceConfig?.BookingSource });

    if (sourceConfig && orderDetails?.Passengers) {
      orderDetails.Addresses && setAddresses(orderDetails.Addresses);

      let bookingDate = orderSummary?.BookingDate!;

      if (bookingDate.charAt(bookingDate.length - 1) === '*') {
        bookingDate = bookingDate.slice(0, -1);
      }

      if (orderSummary?.BookingStatus && orderSummary?.BookingStatus !== 'Closed') {
        const travelDate = orderDetails.Journeys![0].Segments![0].DepartureTime;
        const filteredFee = filterActiveFee(campaigns, {
          bookingSource: sourceConfig.BookingSource!,
          action: 'CANCEL',
          bookingDate: new Date(bookingDate),
          channel: 'CALLCENTER',
          currency: orderSummary?.Currency!,
          travelDate: new Date(travelDate),
        });
        setSelectedFee(filteredFee);
      }
    }

    setHasFlightChanges(false);

    if (midocoFetchedOrder?.OrderDetails?.Journeys?.length && orderDetails?.Journeys?.length) {
      const journeyList: TFF.Journey[] = [];
      for (let journey of midocoFetchedOrder.OrderDetails.Journeys) {
        const segmentList: TFF.Segment[] = [];
        for (let segment of journey.Segments) {
          if (segment.State === 'OK') {
            segment.State = 'HK';
            segmentList.push(segment);
          } else {
            segmentList.push(segment);
          }
        }
        if (segmentList.length) {
          journey.Segments = segmentList;
          journeyList.push(journey);
        }
      }
      midocoFetchedOrder.OrderDetails.Journeys = journeyList;

      for (let i = 0; i < midocoFetchedOrder.OrderDetails.Journeys.length; i++) {
        const diffedJourney = CheckDifferences(
          midocoFetchedOrder?.OrderDetails?.Journeys[i],
          orderDetails?.Journeys[i],
        );
        if (diffedJourney?.diff?.Segments?.flatMap(d => Object.keys(d)).length) {
          setHasFlightChanges(true);
        }
      }
    }
  }, [orderDetails, sourceConfig]);

  const renderRebookFlightComponent = () => {
    return (
      <>
        <Button
          sx={{ alignSelf: 'self-start', paddingBottom: 3, justifyContent: 'start', paddingLeft: 0 }}
          onClick={() => {
            setDisplayRebookFlight(false);
            setActiveFlightMenuButton('');
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '18px',
              width: '18px',
              borderRadius: '9px',
              border: '2px solid #092A5E',
            }}
          >
            <ArrowBackIcon color="primary" sx={{ fontSize: '18px' }} />
          </div>
          <Typography color="primary" variant="h6" style={{ marginLeft: '10px', fontWeight: 400, fontSize: '18px' }}>
            BACK
          </Typography>
        </Button>
        <RebookProvider>
          {orderDetails &&
            orderDetails.Passengers &&
            orderDetails.Journeys?.map((j: TFF.Journey, index) => {
              return (
                <RebookFlightCard
                  orderDetails={orderDetails}
                  key={j.JourneyIdentifier}
                  journey={j}
                  airports={airports ?? []}
                  disableButtons={disableButtons}
                  bookingSource={sourceConfig!.BookingSource!}
                  recordLocator={recordLocator!}
                  selectedAmendBookings={selectedAmendBookings}
                  setSelectedAmendBookings={setSelectedAmendBookings}
                  tfmPnr={retrieveItem?.tfmPnr}
                  rebookCloser={setDisplayRebookFlight}
                  flightDetails={flightDetails}
                  midocoOrderNo={midocoOrderNo}
                  cancelHandler={() => setActiveFlightMenuButton('')}
                />
              );
            })}
        </RebookProvider>
      </>
    );
  };

  const renderAddAncillaries = () => {
    return (
      <>
        <Box
          sx={{
            '@media (max-width: 1599px)': {
              width: '100%',
            },
            width: '100%',
          }}
        >
          <Button
            sx={{ alignSelf: 'self-start', paddingBottom: 3, justifyContent: 'start', paddingLeft: 0 }}
            onClick={() => setDisplayAddAncillaries(false)}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '18px',
                width: '18px',
                borderRadius: '9px',
                border: '2px solid #092A5E',
              }}
            >
              <ArrowBackIcon color="primary" sx={{ fontSize: '18px' }} />
            </div>
            <Typography color="primary" variant="h6" style={{ marginLeft: '10px', fontWeight: 400, fontSize: '18px' }}>
              {intl.formatMessage({ id: 'ancillaries.navigation.back' })}
            </Typography>
          </Button>
        </Box>
        <AncillariesPage flightDetails={flightDetails} midocoOrderNo={midocoOrderNo} tfmPnr={retrieveItem?.tfmPnr} />
      </>
    );
  };

  useEffect(() => {
    if (confirmStatus === 'FAILURE' && confirmError) {
      toast.error('Booking confirmation failed: ' + JSON.stringify(confirmError), {
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }

    if (confirmStatus === 'CONFIRMED' && confirmResponse) {
      toast.success('Booking successfully confirmed.');
      setFlightDetails(confirmResponse.flightDetails!);
      // setDisplayFlightChanges(false);
      handleMenuClicked('');
    }
  }, [confirmStatus, confirmResponse, confirmError]);

  /**
   * Confirm the changes at the source and synchronize
   * them to midoco afterwards.
   */
  const onConfirmBookingHandler = () => {
    if (!orderSummary.SourceDetails) {
      return;
    }
    const confirmBookingRequest: ConfirmBookingRequest = {
      recordLocator: recordLocator!,
      orderId: orderSummary.BookingId!,
      midocoOrderNo: midocoOrderNo,
      syncMidoco: true,
      bookingSource: sourceConfig?.BookingSource! as TypeOfNdcBookingSource,
      ndcAgencyDetails: {
        agencyId: orderSummary.SourceDetails.AgencyId!,
        iataNumber: orderSummary.SourceDetails.IataNumber!,
        officeId: orderSummary.SourceDetails.OfficeId!,
      },
    };
    setConfirmBookingRequest(confirmBookingRequest);
  };

  const renderCancelFlightComponent = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        <Button className={classes.flexRow} onClick={() => setDisplayCancelFlight(false)}>
          <div className={classes.backIconWrapper}>
            <ArrowBackIcon color="primary" />
          </div>
          <Typography color="primary" variant="h6" style={{ marginLeft: '10px' }}>
            BACK
          </Typography>
        </Button>
        <CancelBooking
          serviceFee={selectedFee}
          flightDetails={flightDetails}
          sourceConfig={sourceConfig!}
          onCancel={() => {
            setDisplayCancelFlight(false);
            setActiveFlightMenuButton('');
          }}
          onConfirm={() => alert('Flight Cancelled')}
          airports={airports as TFF.IAirport[]}
          serviceFeeTotalAmount={totalTuiFeeAmount}
          journeysToCancel={selectedJourneysToCancel}
          servicePackagesFee={servicePackageFee}
        />
      </div>
    );
  };

  const renderChangeBookingComponent = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        <Button className={classes.flexRow} onClick={() => setDisplayChangeBooking(false)}>
          <div className={classes.backIconWrapper}>
            <ArrowBackIcon color="primary" />
          </div>
          <Typography color="primary" variant="h6" style={{ marginLeft: '10px' }}>
            BACK
          </Typography>
        </Button>
        <ChangeBooking
          serviceFee={selectedFee}
          flightDetails={flightDetails}
          bookingSource={sourceConfig!.BookingSource!}
          onCancel={() => {
            setDisplayCancelFlight(false);
            setActiveFlightMenuButton('');
          }}
          onConfirm={() => alert('Flight Changed')}
        />
      </div>
    );
  };

  const renderPayments = () => {
    return (
      <div className={classes.paymentTableRoot}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Payment Type</StyledTableCell>
                <StyledTableCell>Details</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Amount</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderDetails?.Payments?.map((payment, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {payment.Id}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {payment.Method}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {payment.Details}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {payment.Status}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {`${payment.Currency === 'EUR' ? '€' : payment.Currency} ${payment.Amount}`}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {payment.CreatedDate ? format(new Date(payment.CreatedDate), 'dd.MM.yyyy HH:mm') : ''}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  const renderPricingDetails = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        <div className={classes.chargesSummaryHeader}>
          <Button
            className={classes.flexRow}
            style={{ margin: 0, marginTop: '20px' }}
            onClick={() => {
              setDisplayEditPassengers(false);
              setOpenNoticeDialog(false);
              setOpenHistoryDialog(false);
              setOpenRebookDialog(false);
              setDisplayChangeBooking(false);
              setDisplayFlightChanges(false);
              setDisplayRebookFlight(false);
              setDisplayAddAncillaries(false);
              setDisplayCancelFlight(false);
              setDisplayPricingDetails(false);
            }}
          >
            <div className={classes.backIconWrapper}>
              <ArrowBackIcon color="primary" />
            </div>
            <Typography color="primary" variant="h6" style={{ marginLeft: '10px' }}>
              BACK
            </Typography>
          </Button>
        </div>
        <div style={{ marginTop: '20px', width: '100%' }}>
          {orderDetails?.Payments && renderPayments()}
          <ChargesSummary
            orderDetails={orderDetails}
            sourceConfig={sourceConfig}
            tuiServiceCharges={midocoFetchedOrder?.OrderDetails?.Fares}
          />
        </div>
        <Typography variant="body1">{intl.formatMessage({ id: 'chargesSummary.pricesInEuro' })}</Typography>
      </div>
    );
  };

  const handleJsonFileUpload = (json: any, type?: string) => {
    const handlers = {
      flightdetails: () => setUploadedJsonFlight(json as TFF.FlightDetails),
      orderdetails: () => {
        const flight = { ...flightDetails, OrderDetails: json };
        setUploadedJsonFlight(flight as TFF.FlightDetails);
      },
      journeys: () => {
        const flight = {
          ...flightDetails,
          OrderDetails: {
            ...flightDetails.OrderDetails,
            Journeys: json,
          },
        };
        setUploadedJsonFlight(flight as TFF.FlightDetails);
      },
    };
    type && handlers[type.toLowerCase()]?.();
  };

  const renderFlightChangesComponent = () => {
    const journeysLength = orderDetails.Journeys.length;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        <Button className={classes.flexRow} onClick={() => setDisplayFlightChanges(false)}>
          <div className={classes.backIconWrapper}>
            <ArrowBackIcon color="primary" />
          </div>
          <Typography color="primary" variant="h6" style={{ marginLeft: '10px' }}>
            {journeysLength
              ? intl.formatMessage({ id: 'flightChanges.yourFlightsHaveChanges' })
              : intl.formatMessage({ id: 'flightChanges.yourFlightHasChanges' })}
          </Typography>
        </Button>

        {window.ENV?.DEBUG_MODE && (
          <JsonFileUploader
            onJsonUpload={handleJsonFileUpload}
            initialJourneys={flightDetails?.OrderDetails?.Journeys}
          />
        )}
        <FlightChanges
          journeysFromMidoco={midocoFetchedOrder?.OrderDetails.Journeys!}
          journeysFromSource={uploadedJsonFlight ? uploadedJsonFlight.OrderDetails.Journeys : orderDetails.Journeys}
          airports={airports as TFF.IAirport[]}
          confirmStatus={confirmStatus}
          confirmError={confirmError}
          onConfirmBookingAfterAdustmentFN={onConfirmBookingHandler}
          onConfirmChangeFN={onConfirmBookingHandler}
          onCancelBookingFN={() => {
            handleMenuClicked('cancelBooking');
            setActiveFlightMenuButton('');
          }}
        />
      </div>
    );
  };

  const renderMsgDialog = () => {
    return (
      <CustomDialog width="md" initialOpen={errorMsg !== null || successMsg !== null} form transparent>
        <Collapse in={errorMsg !== null || successMsg !== null}>
          {(errorMsg !== null || successMsg !== null) && (
            <Alert
              severity={errorMsg !== null ? 'error' : 'success'}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setErrorMsg(null);
                    setSuccessMsg(null);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <AlertTitle>
                <strong>
                  {errorMsg !== null
                    ? intl.formatMessage({ id: 'global.error' })
                    : intl.formatMessage({ id: 'global.success' })}
                </strong>
              </AlertTitle>
              <Typography variant="h6" color="primary">
                {errorMsg}
                {successMsg}
              </Typography>
            </Alert>
          )}
        </Collapse>
      </CustomDialog>
    );
  };

  const handlePaxBack = () => {
    setDisplayEditPassengers(false);
    setPassengers(originPassengers);
  };

  const updatePassengers = responseData => {
    try {
      //setPassengers(responseData.passengers);
      //NDC returns an object, nsk an array?
      flightDetails.OrderDetails.Passengers =
        responseData.passengers ?? Object.values(responseData.airlineDetails?.OrderDetails?.Passengers);
      setSuccessMsg(intl.formatMessage({ id: 'edit.passenger.success' }));
    } catch (er) {
      console.error(er);
      setErrorMsg(intl.formatMessage({ id: 'edit.passenger.unexpectedResponse' }));
    }
    setDisplayEditPassengers(false);
  };
  const renderEditPassengersComponent = () => {
    const backText = intl.formatMessage({ id: 'edit.passenger.back' }); //`Passengers Details`;
    if (!passengers || !sourceConfig) {
      return <></>;
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
        <Button className={classes.flexRow} onClick={() => setDisplayEditPassengers(false)}>
          <div className={classes.backIconWrapper}>
            <ArrowBackIcon color="primary" />
          </div>
          <Typography color="primary" variant="h6" style={{ marginLeft: '10px' }}>
            {backText.toUpperCase()}
          </Typography>
        </Button>
        <Card style={{ backgroundColor: '#E2F3FE', width: '100%' }}>
          <CardContent>
            <Typography style={{ paddingBottom: '10px' }} variant="h5" color="primary">
              <strong>{intl.formatMessage({ id: 'edit.passenger.editPassengers' })}</strong>
            </Typography>
            <PassengersEditForm
              flightDetails={flightDetails}
              sourceGroup={sourceConfig.SourceGroup}
              midocoOrderNo={midocoOrderNo}
              onSuccess={updatePassengers}
              onBack={handlePaxBack}
            />
          </CardContent>
        </Card>
      </div>
    );
  };

  const handleMenuClicked = (buttonId: string) => {
    setActiveFlightMenuButton(buttonId);

    setOpenCancelFlightDialog(false);
    setDisplayCancelFlight(false);
    setOpenNoticeDialog(false);
    setDisplayRebookFlight(false);
    setDisplayAddAncillaries(false);
    setDisplayEditPassengers(false);
    setOpenHistoryDialog(false);
    setOpenRebookDialog(false);
    setDisplayChangeBooking(false);
    setDisplayFlightChanges(false);
    setDisplayPricingDetails(false);
    setDisplayACRTriggerDialog(false);

    switch (buttonId) {
      case `pricingDetails`:
        setDisplayPricingDetails(true);
        break;
      case `notice`:
        setOpenNoticeDialog(true);
        break;
      case `rebook`:
        if (orderDetails.Journeys[0].FareFamily === 'Pure') {
          setOpenRebookDialog(true);
        } else {
          setDisplayRebookFlight(true);
        }

        break;
      case `history`:
        setOpenHistoryDialog(true);
        break;
      case `cancelBooking`:
        if (
          sourceConfig!.BookingSource === 'TUI-NSK' &&
          orderSummary.JourneyType !== 'ONEWAY' &&
          window.ENV?.ENABLE_SPLIT_CANCEL === true
        ) {
          setOpenCancelFlightDialog(true);
          setSelectedJourneysToCancel([]);
        } else {
          setDisplayCancelFlight(true);
          setSelectedJourneysToCancel(orderDetails.Journeys);
        }
        break;
      case `changeBooking`:
        setDisplayChangeBooking(true);
        break;
      case `changes`:
        setDisplayFlightChanges(true);
        break;
      case `passengerChanges`:
        setDisplayEditPassengers(true);
        break;
      case 'triggerPNRchangeToAcr':
        setDisplayACRTriggerDialog(true);
        break;
      case 'ocnman':
        if (orderSummary.RecordLocator && sourceConfig?.BookingSource) {
          setRerunInvRequest({
            recordLocator: orderSummary.RecordLocator,
            bookingSource: sourceConfig.BookingSource! as TypeOfNdcBookingSource,
          });
        }
        break;
      default:
        break;
    }
  };

  const renderNoticeTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <IntlMessages id="menu.content" />
              </TableCell>
              <TableCell>
                <IntlMessages id="menu.contentType" />
              </TableCell>
              <TableCell>
                <IntlMessages id="menu.createdAt" />
              </TableCell>
              <TableCell>
                <IntlMessages id="menu.createdBy" />
              </TableCell>
              <TableCell>
                <IntlMessages id="menu.channel" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {messageServiceItems
              ?.filter(m => m.type === 'NOTICE')
              .map((m, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography variant="body1" color="primary" align="left">
                      {m.content}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="primary" align="left">
                      {m.contentType}
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <strong>{m.createdAt ? format(new Date(m.createdAt), 'dd.MM.yyyy HH:mm') : null}</strong>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="primary" align="left">
                      {m.createdBy}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="primary" align="left">
                      {m.channel}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const handleAddNotice = () => {
    setOpenAddNoticeDialog(false);
    if (notice) {
      const msg = new Message(
        keycloak?.profile?.email ?? 'UNKNOWN',
        orderSummary?.RecordLocator as string,
        'CALLCENTER',
        'NOTICE',
        sourceConfig?.Id!,
        'ADD_NOTICE',
      );
      msg.content = notice;
      addMessage(msg);
    }
  };

  const renderAddNoticeDialog = () => {
    return (
      <CustomDialog
        onCancel={() => {
          setOpenAddNoticeDialog(false);
          setActiveFlightMenuButton('');
        }}
        onConfirm={() => {
          handleAddNotice();
          setActiveFlightMenuButton('');
        }}
        initialOpen={openAddNoticeDialog}
        width="lg"
        confirmButtonText={'Hinzufügen'}
        cancelButtonText={intl.formatMessage({ id: 'pages.flightCombination.close' })}
        title={
          <Typography variant="h5" align="left">
            {intl.formatMessage({ id: 'pages.flightCombination.addNote' })}
          </Typography>
        }
      >
        <TextField
          label={intl.formatMessage({ id: 'menu.notice.notice' })}
          placeholder={intl.formatMessage({ id: 'menu.notice.placeholder' })}
          onChange={event => setNotice(event.target.value)}
          fullWidth
        />
      </CustomDialog>
    );
  };

  const renderNoticeDisplayDialog = () => {
    const notices = messageServiceItems?.filter(m => m.type === 'NOTICE').length;
    return (
      <CustomDialog
        onCancel={() => {
          setOpenNoticeDialog(false);
          setActiveFlightMenuButton('');
        }}
        onConfirm={() => {
          setOpenAddNoticeDialog(true);
          setOpenNoticeDialog(false);
        }}
        initialOpen={openNoticeDialog}
        width="lg"
        confirmButtonText={intl.formatMessage({ id: 'pages.flightCombination.addNote' })}
        cancelButtonText={intl.formatMessage({ id: 'pages.flightCombination.close' })}
        title={
          <Typography variant="h5" align="left">
            {intl.formatMessage({ id: 'pages.flightCombination.notesRecordlocator' })}{' '}
            <strong>{orderSummary?.RecordLocator}</strong>
          </Typography>
        }
      >
        {notices === 0 ? (
          <Typography variant="h5" align="center">
            <IntlMessages id="pages.flightCombination.notesNotFound" />
          </Typography>
        ) : (
          renderNoticeTable()
        )}
      </CustomDialog>
    );
  };

  const renderHistoryTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <IntlMessages id="menu.createdAt" />
              </TableCell>
              <TableCell>
                <IntlMessages id="menu.createdBy" />
              </TableCell>
              <TableCell>
                <IntlMessages id="menu.action" />
              </TableCell>
              <TableCell>
                <IntlMessages id="menu.channel" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {messageServiceItems?.map((m, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <strong>{m.createdAt ? format(new Date(m.createdAt), 'dd.MM.yyyy HH:mm') : null}</strong>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color="primary" align="left">
                    {m.createdBy}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" color="primary" align="left">
                    {m.action}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color="primary" align="left">
                    {m.channel}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderHistoryDialog = () => {
    return (
      <CustomDialog
        onCancel={() => {
          setOpenHistoryDialog(false);
          setActiveFlightMenuButton('');
        }}
        confirmButton={false}
        initialOpen={openHistoryDialog}
        width="lg"
        confirmButtonText={intl.formatMessage({ id: 'pages.flightCombination.addNote' })}
        cancelButtonText={intl.formatMessage({ id: 'pages.flightCombination.close' })}
        title={
          <Typography variant="h5" align="left">
            {intl.formatMessage({ id: 'pages.flightCombination.detailsRecordlocator' })} : {orderSummary?.RecordLocator}
          </Typography>
        }
      >
        {renderHistoryTable()}
      </CustomDialog>
    );
  };

  const renderHintDialog = () => {
    return (
      <CustomDialog width="md" initialOpen={openHint} form transparent>
        <Collapse in={openHint}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenHint(false);
                  setDisableButtons(true);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle>
              <strong>{intl.formatMessage({ id: 'hint.info' })}</strong>
            </AlertTitle>
            <Typography variant="h6" color="primary">
              {intl.formatMessage({ id: 'hint.involuntaryChange.message' })}
            </Typography>
          </Alert>
        </Collapse>
      </CustomDialog>
    );
  };

  const renderRebookDialog = () => {
    return (
      <>
        <CustomDialog
          onCancel={() => {
            setOpenRebookDialog(false);
            setActiveFlightMenuButton('');
          }}
          initialOpen={openRebookDialog}
          confirmButton
          confirmButtonText={intl.formatMessage({ id: 'pages.flightDetails.rebook.model.confirmation' })}
          confirmButtonStyle={{
            backgroundColor: '#F2E30F',
            color: 'black',
          }}
          onConfirm={() => {
            setOpenRebookDialog(false);
            setDisplayRebookFlight(true);
          }}
          width="md"
          cancelButtonText={intl.formatMessage({ id: 'global.no' })}
          titleBg="#E2F3FE"
          title={
            <Typography variant="h5" align="left" fontWeight="bold">
              {intl.formatMessage({ id: 'pages.flightDetails.rebook.model.headline' })}
            </Typography>
          }
        >
          <Typography variant="h6" align="left" style={{ marginTop: '20px' }}>
            {intl.formatMessage({ id: 'pages.flightDetails.rebook.model.processing' })}
          </Typography>
        </CustomDialog>
      </>
    );
  };

  const renderCancelJourneyDetails = (j: TFF.Journey) => {
    const origin = j.Segments[0];
    const departureDate = new Date(origin.DepartureTime).toLocaleDateString();
    const departureTime = new Date(origin.DepartureTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const originAirport = fetchedAirports?.find(a => a.id === origin.Origin);

    const destination = j.Segments[j.Segments.length === 0 ? 0 : j.Segments.length - 1];
    const arrivalTime = new Date(destination.ArrivalTime).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
    const destinationAirport = fetchedAirports?.find(a => a.id === destination.Destination);

    return (
      <p>
        {departureDate} <br />
        <span>
          {departureTime} {originAirport?.nameDE}
        </span>
        <span> - </span>
        <span>
          {arrivalTime} {destinationAirport?.nameDE}
        </span>
      </p>
    );
  };

  const renderCancelBookingDialog = () => {
    const journeys = flightDetails.OrderDetails.Journeys;
    return (
      <CustomDialog
        onCancel={() => {
          setOpenCancelFlightDialog(false);
          setSelectedJourneysToCancel([]);
          setActiveFlightMenuButton('');
        }}
        initialOpen={openCancelFlightDialog}
        confirmButton
        confirmButtonText={intl.formatMessage({ id: 'pages.flightCombination.continue' })}
        confirmButtonStyle={{
          backgroundColor: '#F2E30F',
          color: 'black',
        }}
        onConfirm={() => {
          setOpenCancelFlightDialog(false);
          setDisplayCancelFlight(true);
        }}
        confirmDisabled={selectedJourneysToCancel?.length === 0}
        width="md"
        cancelButtonText={intl.formatMessage({ id: 'pages.flightCombination.cancel' })}
        titleBg="#E2F3FE"
        title={
          <Typography variant="h5" align="left" fontWeight="bold">
            {intl.formatMessage({ id: 'pages.flightCombination.cancelBookingTitle' }).toUpperCase()}
          </Typography>
        }
      >
        <Typography variant="h6" align="left" style={{ marginTop: '20px' }}>
          {intl.formatMessage({ id: 'pages.flightCombination.whichFlightToCancel' })}
        </Typography>
        <FormControl style={{ width: '100%', marginTop: '20px' }}>
          <RadioGroup
            aria-labelledby="radio-buttons-group-label"
            name="radio-buttons-group"
            style={{ marginLeft: '20px' }}
          >
            {journeys.map((j, index) => {
              return (
                <FormControlLabel
                  key={j.Id}
                  className={classes.formControlLabel}
                  value={j.Ond}
                  control={<Radio></Radio>}
                  label={
                    <>
                      <Typography variant="h6" align="left" color="primary" fontWeight="bold">
                        {intl.formatMessage({
                          id:
                            index === 0
                              ? 'pages.flightCombination.cancelOutbound'
                              : 'pages.flightCombination.cancelReturn',
                        })}
                      </Typography>
                      {renderCancelJourneyDetails(j)}
                    </>
                  }
                  onClick={() => handleCancelBookingSelection(j)}
                />
              );
            })}
            <FormControlLabel
              className={classes.formControlLabel}
              style={{ borderBottom: '1px solid grey' }}
              value="all"
              control={<Radio></Radio>}
              label={
                <Typography variant="h6" align="left" color="primary" fontWeight="bold">
                  {intl.formatMessage({ id: 'pages.flightCombination.cancelAll' })}
                </Typography>
              }
              onClick={() => handleCancelBookingSelection()}
            />
          </RadioGroup>
        </FormControl>
      </CustomDialog>
    );
  };

  const handleCancelBookingSelection = (journey?: TFF.Journey) => {
    if (journey) {
      setSelectedJourneysToCancel([journey]);
    } else {
      setSelectedJourneysToCancel(orderDetails.Journeys);
    }
  };

  const showMain = () => {
    return (
      !displayChangeBooking &&
      !displayRebookFlight &&
      !displayAddAncillaries &&
      !displayCancelFlight &&
      !displayFlightChanges &&
      !displayEditPassengers &&
      !displayPricingDetails
    );
  };

  const renderFlightDetails = () => {
    const displayMain = showMain();
    return (
      <div className={classes.root}>
        <div
          style={{ position: 'fixed', width: '100vw', zIndex: 2 }}
          className={`${isShrunk ? classes.menuRootShrunk : classes.menuRoot}`}
        >
          <BookingDetailHeader
            dbRetrieveItem={retrieveItem}
            flightDetails={flightDetails}
            sourceConfig={sourceConfig}
            midocoFlightDetails={midocoFetchedOrder}
            midocoOrderNo={midocoOrderNo}
            onMenuClicked={handleMenuClicked}
            hasFlightChanges={hasFlightChanges}
            disableButtons={disableButtons}
            isShrunk={isShrunk}
            serviceFeeTotalAmount={totalTuiFeeAmount}
            activeButton={activeFlightMenuButton}
          />
        </div>
        {/**divider */}
        <div style={{ height: '260px', width: '100%' }}></div>
        {displayRebookFlight && renderRebookFlightComponent()}
        {displayAddAncillaries && renderAddAncillaries()}
        {displayCancelFlight && renderCancelFlightComponent()}
        {displayChangeBooking && renderChangeBookingComponent()}
        {displayFlightChanges && renderFlightChangesComponent()}
        {displayEditPassengers && renderEditPassengersComponent()}
        {displayPricingDetails && renderPricingDetails()}
        {displayMain && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {orderDetails &&
              orderDetails.Passengers &&
              recordLocator &&
              orderDetails.Journeys?.map((j: TFF.Journey) => {
                return (
                  <FlightDetailCard
                    fee={selectedFee}
                    orderDetails={orderDetails}
                    key={j.JourneyIdentifier}
                    journey={j}
                    airports={airports ?? []}
                    disableButtons={disableButtons}
                    bookingSource={sourceConfig!.BookingSource!}
                    recordLocator={recordLocator}
                    tfmPnr={retrieveItem?.tfmPnr ?? 'NOT SET'}
                    midocoOrderNo={midocoOrderNo}
                  />
                );
              })}
            {<AncillariesCard flightDetails={flightDetails} handleEdit={() => setDisplayAddAncillaries(true)} />}
            {passengers && (
              <PassengerDetailCard
                allowEdit={!changeActionsDisabled}
                handleEditBtn={() => setDisplayEditPassengers(!displayEditPassengers)}
                flightDetails={flightDetails}
                midocoOrderNo={midocoOrderNo}
              />
            )}
            {addresses && midocoFetchedOrder && sourceConfig && (
              <ContactsDetailCard
                flightDetails={flightDetails}
                midocoOrderNo={midocoOrderNo}
                allowEdit={!changeActionsDisabled}
                midocoDetails={midocoFetchedOrder}
                addresses={orderDetails?.Addresses ?? []}
                sourceGroup={sourceConfig.SourceGroup}
                handleEditBtn={() => setDisplayEditPassengers(!displayEditPassengers)}
              />
            )}
            {<ServicesCard serviceResponse={serviceResponse} />}
            <PricingDetailCard
              flightDetails={flightDetails}
              serviceFee={selectedFee}
              midocoFlightDetails={midocoFetchedOrder}
              serviceFeeTotalAmount={totalTuiFeeAmount}
              onDetailClick={() => setDisplayPricingDetails(true)}
              servicePackageFee={servicePackageFee}
            />
          </div>
        )}
        {window.ENV?.DEBUG_MODE && (
          <>
            <ReactJson name={'DEBUG:FlightDetails'} src={flightDetails} collapsed={true} />
            <ReactJson name={'DEBUG:MidocoDetails'} src={midocoFetchedOrder!} collapsed={true} />
            <ReactJson name={'DEBUG:fetchedRetrieveDbItem'} src={retrieveItem!} collapsed={true} />
          </>
        )}
        {renderAddNoticeDialog()}
        {renderNoticeDisplayDialog()}
        {renderHistoryDialog()}
        {renderHintDialog()}
        {renderMsgDialog()}
        {renderRebookDialog()}
        {renderCancelBookingDialog()}
      </div>
    );
  };

  return renderFlightDetails();
};

export default FlightDetails;
