import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import Table from '../Table';
import { columnDefinition } from './column-definitions';
import { MUIDataTableOptions } from 'mui-datatables';
import CustomDialog from '../Dialogs';
import { useAtcomResTasks } from '../../hooks/use-atcomres-tasks';
import { TaskDynamoItem } from '@tff/types/TFF';
import { IntlMessages } from '../../util';

const InvoluntaryChangesAtCom = () => {
  const [retrieveItems, setRetrieveItems] = useState<TaskDynamoItem[]>([]);
  const [openProgressDialog, setOpenProgressDialog] = useState<boolean>(false);
  const [openAcceptWithChangesDialog, setOpenAcceptWithChangesDialog] = useState<boolean>(false);
  const [openAcceptWithoutChangesDialog, setOpenAcceptWithoutChangesDialog] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [retrievesToChange, setRetrievesToChange] = useState<TaskDynamoItem[]>([]);

  const { fetchedAtcomResTaskList, changeStateOfSelected } = useAtcomResTasks();

  useEffect(() => {
    if (fetchedAtcomResTaskList && fetchedAtcomResTaskList.length > 0) {
      setRetrieveItems(fetchedAtcomResTaskList);
    }
  }, [fetchedAtcomResTaskList]);

  useEffect(() => {
    if (selectedRows) {
      const retrievesToChange: TaskDynamoItem[] = [];
      selectedRows.forEach(row => retrievesToChange.push(retrieveItems[row]));
      setRetrievesToChange(retrievesToChange);
    }
  }, [selectedRows]);

  type CustomToolbarProps = {
    selectedRows: number[];
  };

  const handleInProgressToolbar = (selectedRows: number[]) => {
    setSelectedRows(selectedRows);
    setOpenProgressDialog(true);
  };

  const handleAcceptWithChangesToolbar = (selectedRows: number[]) => {
    setSelectedRows(selectedRows);
    setOpenAcceptWithChangesDialog(true);
  };

  const handleAcceptWithoutChangesToolbar = (selectedRows: number[]) => {
    setSelectedRows(selectedRows);
    setOpenAcceptWithoutChangesDialog(true);
  };

  const CustomToolbar: React.FC<CustomToolbarProps> = ({ selectedRows }) => {
    return (
      <div style={{ margin: 5 }}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleInProgressToolbar(selectedRows)}
          style={{ marginRight: 5 }}
        >
          <IntlMessages id="pages.involuntary-changes.set-in-progress" />
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleAcceptWithChangesToolbar(selectedRows)}
          style={{ marginRight: 5 }}
        >
          <IntlMessages id="pages.involuntary-changes.accept-with-changes" />
        </Button>
        <Button color="primary" variant="contained" onClick={() => handleAcceptWithoutChangesToolbar(selectedRows)}>
          <IntlMessages id="pages.involuntary-changes.accept-without-changes" />
        </Button>
      </div>
    );
  };

  const tableOptions: MUIDataTableOptions = {
    selectableRows: 'multiple',
    selectableRowsHeader: true,
    customToolbarSelect: selectedRows => {
      return <CustomToolbar selectedRows={selectedRows.data.map(d => d.index)} />;
    },
  };

  return (
    <Grid item xs={12}>
      <Table
        data={retrieveItems}
        columnsData={columnDefinition}
        tableId="involuntaryChangesAtCom"
        tableName={'Involuntary Changes AtCom'}
        otherOptions={tableOptions}
      />
      {openProgressDialog && (
        <CustomDialog
          initialOpen={openProgressDialog}
          onCancel={() => setOpenProgressDialog(false)}
          onConfirm={() => {
            changeStateOfSelected('OPEN', 'IN_PROGRESS', retrievesToChange);
            setOpenProgressDialog(false);
            // resetAtcomResTaskState();
          }}
          width="md"
        >
          <Typography color="primary" variant="h6">
            Change the state of selected bookings to <strong>in progress</strong>
          </Typography>
        </CustomDialog>
      )}
      {openAcceptWithChangesDialog && (
        <CustomDialog
          initialOpen={openAcceptWithChangesDialog}
          onCancel={() => setOpenAcceptWithChangesDialog(false)}
          onConfirm={() => {
            changeStateOfSelected('AUTOCONFIRMED', 'NEW', retrievesToChange);
            setOpenAcceptWithChangesDialog(false);
            // resetAtcomResTaskState();
          }}
          width="md"
        >
          <Typography color="primary" variant="h6">
            <IntlMessages id="pages.involuntary-changes.change-state-of-booking" />
            <strong>
              <IntlMessages id="pages.involuntary-changes.accept-with-changes" />
            </strong>
          </Typography>
        </CustomDialog>
      )}
      {openAcceptWithoutChangesDialog && (
        <CustomDialog
          initialOpen={openAcceptWithoutChangesDialog}
          onCancel={() => setOpenAcceptWithoutChangesDialog(false)}
          onConfirm={() => {
            changeStateOfSelected('CONFIRMED', 'CLOSE', retrievesToChange);
            setOpenAcceptWithoutChangesDialog(false);
            // resetAtcomResTaskState();
          }}
          width="md"
        >
          <Typography color="primary" variant="h6">
            <IntlMessages id="pages.involuntary-changes.change-state-of-booking" />
            <strong>
              <IntlMessages id="pages.involuntary-changes.accept-without-changes" />
            </strong>
          </Typography>
        </CustomDialog>
      )}
    </Grid>
  );
};

export default InvoluntaryChangesAtCom;
