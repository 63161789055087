import * as React from 'react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Theme, Typography, Box, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { TFF } from '@tff/types';
import RebookHorizontalTrack from './RebookHorizantalTrack';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '60%',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: '5px',
      borderColor: theme.palette.text.primary,
      margin: '10px 20px',
      position: 'relative',
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      backgroundColor: 'white',
    },
    flexCenter: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '0px 20px',
    },
    iconWrapper: {
      height: 40,
      width: 40,
      backgroundColor: theme.palette.text.primary,
      borderRadius: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    infoIcon: {
      position: 'absolute',
      right: -10,
      top: -10,
      height: 20,
      width: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#D40E14',
      color: 'white',
      borderRadius: 10,
    },
    menuWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: '100px',
      width: '100%',
      padding: '10px 20px',
      backgroundColor: '#E2F3FE',
    },
    menuButtonsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 400,
    },
    flightDetailRoot: {
      margin: '20px 0',
      '& .MuiCollapse-root': {
        width: '100%',
      },
      '& .MuiCollapse-entered': {
        marginBottom: '-20px',
      },
    },
    flightTrackLine: {
      height: '2px',
      backgroundColor: theme.palette.text.primary,
      width: '60%',
      alignSelf: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    flightTrackDot: {
      height: '12px',
      width: '12px',
      borderRadius: '6px',
      backgroundColor: theme.palette.text.primary,
    },
    flightDate: {
      marginLeft: 55,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
  }),
);

interface IFlightTrackProps {
  journey: TFF.Journey;
  airports: TFF.IAirport[];
  orderDetails: TFF.OrderDetails;
}

const RebookFlightTrack: React.FC<IFlightTrackProps> = ({ journey, airports, orderDetails }) => {
  const classes = useStyles();
  const intl = useIntl();

  const segments = journey.Segments;

  const firstSegment = segments![0];

  const changeWidth = useMediaQuery('(max-width: 1240px)');

  const cabineclass = useMemo(() => {
    if (!journey) return null;
    return intl.formatMessage({ id: `cabinClass.${journey.CabinClass}` });
  }, [orderDetails]);

  /**
   * @description Find aggregated lift status, if 'Default' is found it returns an empty string, or if more than one status is found then returns others.
   * @return string
   */
  const liftStatus: JSX.Element = useMemo(() => {
    const resultArr: string[] = [];

    if (journey?.LiftStatusAggregated) {
      journey.LiftStatusAggregated.forEach(s => {
        if (s !== 'Default') {
          resultArr.push(s);
        }
      });
    }
    return resultArr.length ? (
      <div id={'aggregated-lift-status'} className={classes.flexCenter} style={{ marginTop: 0, paddingTop: 0 }}>
        <strong>Lift-status: {resultArr.join(', ')}</strong>
      </div>
    ) : (
      <></>
    );
  }, [journey.LiftStatusAggregated]);

  return (
    <>
      <div className={classes.flexCenter} style={{ marginTop: 0, paddingTop: 0 }}>
        <div className={classes.flightDate}>
          <Typography component={'span'} color="primary" variant="subtitle1" fontSize="18px" marginLeft={-3}>
            <strong>
              {firstSegment?.MarketingCarrier} {firstSegment?.FlightNumber} &nbsp;
            </strong>
            {/* {`${weekday(firstSegment?.DepartureTime, locale.locale).substring(0, 2)}. ${formatDate(
              firstSegment?.DepartureTime,
            )}`} */}
          </Typography>
          <Typography component={'span'} variant="subtitle1" color="primary">
            {/* <strong>{firstSegment?.DepartureTime.split('T').pop()?.substring(0, 5)}</strong> */}
          </Typography>
        </div>
        <RebookHorizontalTrack journey={journey} airports={airports} />
        <Box
          sx={{
            fontSize: '30px',
            marginLeft: {
              sm: '100px',
              md: changeWidth ? '100px' : '-100px',
              lg: '-0px',
            },
          }}
          className="tui-icon tui-icon--seat"
        >
          <span style={{ fontSize: '14px', padding: 5 }}>{`${cabineclass} Class`}</span>
        </Box>
        <div className={classes.flightDate}>
          {/* {`${weekday(lastSegment?.ArrivalTime, locale.locale).substring(0, 2)}. ${formatDate(
              lastSegment?.ArrivalTime,
            )}`} */}

          <Typography component={'span'} variant="subtitle1" color="primary">
            {/* <strong>{lastSegment?.ArrivalTime.split('T').pop()?.substring(0, 5)}</strong> */}
          </Typography>
        </div>
      </div>

      {liftStatus}
    </>
  );
};

export default RebookFlightTrack;
