import * as React from 'react';
import { useEffect, useContext } from 'react';
import { LoadingContext } from '../contexts/LoadingContextProvider';
import { AxiosResponse } from 'axios';
import { useQueryClient } from 'react-query';
import { TaskDynamoItem, UpdateTaskRequest, TypeOfTaskDecision, TypeOfTaskStatus } from '@tff/types/TFF';
import { keycloak } from '../keycloak';
import _ from 'lodash';
import { apigeeEndpoint } from '../apis';

export type TypeOfAtcomResTaskStatus = 'IN_FETCHING' | 'UPDATING' | 'FETCHED' | 'UPDATED' | 'FAILURE';

export type AtcomResTasksResponse = {
  fetchedAtcomResTaskList?: TaskDynamoItem[];
  fetchedAtcomResTaskStatus?: TypeOfAtcomResTaskStatus;
  fetchedAtcomResTaskError?: any;
  resetAtcomResTaskState: () => void;
  changeStateOfSelected: (
    decision: TypeOfTaskDecision,
    status: TypeOfTaskStatus,
    selectedIDs: Partial<TaskDynamoItem>[],
  ) => void;
};

//
export const useAtcomResTasks = (): AtcomResTasksResponse => {
  const [fetchedAtcomResTaskStatus, setFetchedAtcomResTaskStatus] = React.useState<
    TypeOfAtcomResTaskStatus | undefined
  >(undefined);
  const [fetchedAtcomResTaskList, setFetchedAtcomResTaskList] = React.useState<TaskDynamoItem[] | undefined>(undefined);
  const [fetchedAtcomResTaskError, setFetchedAtcomResTaskError] = React.useState(undefined);
  const [tasksToUpdate, setTasksToUpdate] = React.useState<UpdateTaskRequest[] | undefined>(undefined);
  const { showLoading, closeLoading } = useContext(LoadingContext);
  const queryClient = useQueryClient();

  const resetAtcomResTaskState = (): void => {
    setFetchedAtcomResTaskError(undefined);
    setFetchedAtcomResTaskStatus(undefined);
    setFetchedAtcomResTaskList(undefined);
    setTasksToUpdate(undefined);
  };

  const changeStateOfSelected = (
    decision: TypeOfTaskDecision,
    status: TypeOfTaskStatus,
    selectedTasks: Partial<TaskDynamoItem>[],
  ): void => {
    const tasksToUpdate: UpdateTaskRequest[] | undefined = selectedTasks?.map<UpdateTaskRequest>(ndcTaskToUpdate => {
      const updateTaskRequest: UpdateTaskRequest = {
        ndcTaskToUpdate: { ...ndcTaskToUpdate, decision, status },
        updateRemark: `Changes decision to ${decision} by TFF-ATCOMRES-INVOLUNTARY-CHANGES`,
        updatedBy: keycloak?.profile?.username!,
      };
      return updateTaskRequest;
    });

    setTasksToUpdate(tasksToUpdate);
  };

  useEffect(() => {
    const fetchDataFromBackend = async (): Promise<void> => {
      try {
        setFetchedAtcomResTaskStatus('IN_FETCHING');
        let atcomResTaskResponse: TaskDynamoItem[] = [];
        let response: AxiosResponse<TaskDynamoItem[]> | undefined;

        response = await queryClient.fetchQuery(
          `get:atcomResTaskItemList`,
          async () => {
            showLoading('Loading involuntary tasks for AtcomRes');
            return await apigeeEndpoint.get<TaskDynamoItem[]>(`/ndc/task/open?channel=atcomres`);
          },
          {
            retry: 2,
            // staleTime: 2000,
            cacheTime: 5000,
            initialData: () => {
              return queryClient.getQueryData(`get:atcomResTaskItemList`);
            },
          },
        );
        atcomResTaskResponse = response?.data;

        if (response?.data && 'errorMessage' in response?.data) {
          throw response?.data['errorMessage'];
        }

        setFetchedAtcomResTaskList(atcomResTaskResponse);
        setFetchedAtcomResTaskStatus('FETCHED');
      } catch (error) {
        setFetchedAtcomResTaskError(error);
        setFetchedAtcomResTaskStatus('FAILURE');
      } finally {
        closeLoading();
      }
    };

    if (!fetchedAtcomResTaskStatus) {
      void fetchDataFromBackend();
    }
  }, []);

  useEffect(() => {
    if (!tasksToUpdate) {
      return;
    }
    const updateOnBackend = async (): Promise<void> => {
      try {
        setFetchedAtcomResTaskStatus('UPDATING');
        const modifiedTaskList = _.cloneDeep(fetchedAtcomResTaskList);
        showLoading(`Update ${tasksToUpdate.length} Tasks for AtcomRes`);
        for (let updateTaskRequest of tasksToUpdate) {
          const response = await apigeeEndpoint.post<TaskDynamoItem>(`/ndc/task`, updateTaskRequest);
          const index: number = modifiedTaskList?.findIndex(
            (task: TaskDynamoItem) =>
              task.partitionKey === updateTaskRequest.ndcTaskToUpdate.partitionKey &&
              task.sortKey === updateTaskRequest.ndcTaskToUpdate.sortKey,
          )!;
          if (response.data) {
            modifiedTaskList?.splice(index, 1, response.data);
          }
        }
        setFetchedAtcomResTaskList(modifiedTaskList);

        setFetchedAtcomResTaskStatus('UPDATED');
      } catch (error) {
        setFetchedAtcomResTaskError(error);
        setFetchedAtcomResTaskStatus('FAILURE');
      } finally {
        closeLoading();
        setTasksToUpdate(undefined);
      }
    };

    if (tasksToUpdate && tasksToUpdate.length > 0) {
      void updateOnBackend();
    }
  }, [tasksToUpdate]);

  return {
    fetchedAtcomResTaskList: fetchedAtcomResTaskList,
    fetchedAtcomResTaskStatus,
    fetchedAtcomResTaskError,
    resetAtcomResTaskState,
    changeStateOfSelected,
  };
};
