import { useEffect, useState } from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { MenuItem, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { renderInput, renderSelectField } from '../Fields';
import CustomButton from '../../Button';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Alert from '@mui/material/Alert';

// @ts-ignore
import * as logo from '../../../assets/images/logo.png';
import { useTypedSelector } from '../../../reducers';
import { IntlMessages } from '../../../util';
import { useNavigate } from 'react-router-dom';

export interface ISearchOrderFormData {
  idType?: 'bookingID' | 'clientPnr' | 'tfmPnr' | 'midocoID' | 'AF' | 'KL' | 'EK' | 'NSK' | 'SQ' | 'NDCX' | 'AMADEUS';
  crsBookingId?: string;
  forename?: null | string;
  surname?: null | string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: '40px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    title: {
      fontSize: '15pt',
      padding: 15,
    },
    spacer: {
      height: 20,
    },
    formWrapper: {
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        width: '80%',
      },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    btnWrapper: {
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        width: '80%',
      },
      display: 'flex',
      justifyContent: 'space-between',
    },
    info: {
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        width: '80%',
      },
      border: '0px solid black',
      paddingLeft: 0,
    },
  }),
);

const SearchOrderForm = (props: InjectedFormProps<ISearchOrderFormData>) => {
  const { handleSubmit, pristine, reset, submitting } = props;
  const { searchOrderForm } = useTypedSelector(({ form }) => form);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [disableId, setDisableID] = useState<boolean>(false);
  const navigate = useNavigate();

  const intl = useIntl();

  const resetForm = () => {
    reset();
    navigate(`/tff/order/search`);
  };

  const classes = useStyles();

  const formValues: ISearchOrderFormData = searchOrderForm?.values;

  useEffect(() => {
    setDisabled(!!formValues?.crsBookingId);
    // const disableID = formValues?.forename || formValues?.surname || formValues?.city ? true : false;
    const disableID = !!(formValues?.forename || formValues?.surname);
    setDisableID(disableID);
  }, [formValues]);

  return (
    <Form onSubmit={handleSubmit} className={classes.root}>
      <div className={classes.paper}>
        <Link className="logo-lg" style={{ width: 90, height: 40 }} to="/" title="Flight Order Fulfillment">
          <img src={logo.default} width={'100%'} alt="Flight Order Fulfillment" title="Flight Order Fulfillment" />
        </Link>
        <Typography variant="h5" color="primary" style={{ margin: '20px 0px', fontWeight: 700 }}>
          TUI Flight Fulfillment
        </Typography>
        <div className={classes.formWrapper}>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="idType"
                fullWidth
                label="ID Type"
                component={renderSelectField}
                variant="filled"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                  },
                }}
                disabled={disableId}
              >
                <MenuItem value="bookingID">
                  <IntlMessages id={'pages.orderSearchPage.label.recordLocator'} />
                </MenuItem>
                <MenuItem value="midocoID">
                  <IntlMessages id={'pages.orderSearchPage.label.midocoid'} />
                </MenuItem>
                <MenuItem value="NSK">NSK</MenuItem>
                <MenuItem value="AF">Air France</MenuItem>
                <MenuItem value="KL">KLM</MenuItem>
                <MenuItem value="EK">Emirates</MenuItem>
                <MenuItem value="SQ">Singapore Airlines</MenuItem>
                <MenuItem value="NDCX">NDCX</MenuItem>
                <MenuItem value="AMADEUS">AMADEUS</MenuItem>
                <MenuItem value="clientPnr">AtComRes-BookingId</MenuItem>
                <MenuItem value="tfmPnr">TFM-Recordlocator</MenuItem>
              </Field>
            </div>
            <div className="col-md-6">
              <Field
                name="crsBookingId"
                fullWidth
                label="ID"
                component={renderInput}
                variant="filled"
                inputProps={{
                  style: {
                    backgroundColor: 'white',
                    textTransform: 'uppercase',
                  },
                }}
                InputLabelProps={{ shrink: true }}
                disabled={disableId}
              />
            </div>
          </div>
          <div className={classes.spacer} />
          <Field
            name="surname"
            fullWidth
            label={intl.formatMessage({ id: 'pages.orderSearchPage.label.lastname' })}
            component={renderInput}
            variant="filled"
            InputProps={{
              style: {
                backgroundColor: 'white',
              },
            }}
            InputLabelProps={{ shrink: true }}
            disabled={disabled}
          />
          <div className={classes.spacer} />
          <Field
            name="forename"
            fullWidth
            label={intl.formatMessage({ id: 'pages.orderSearchPage.label.firstname' })}
            component={renderInput}
            variant="filled"
            disabled={!formValues?.surname}
            InputProps={{
              style: {
                backgroundColor: 'white',
              },
            }}
            InputLabelProps={{ shrink: true }}
          />
          {/*<div className={classes.spacer} />*/}
          {/*<Field*/}
          {/*  name="city"*/}
          {/*  fullWidth*/}
          {/*  label={intl.formatMessage({ id: 'pages.orderSearchPage.label.city' })}*/}
          {/*  component={renderInput}*/}
          {/*  variant="filled"*/}
          {/*  InputProps={{*/}
          {/*    style: {*/}
          {/*      backgroundColor: 'white',*/}
          {/*    },*/}
          {/*  }}*/}
          {/*  InputLabelProps={{ shrink: true }}*/}
          {/*  disabled={disabled}*/}
          {/*/>*/}
        </div>
        <div className={classes.spacer} />

        <Alert
          variant="outlined"
          icon={<InfoOutlinedIcon fontSize="small" color={'primary'} />}
          severity="info"
          className={classes.info}
        >
          <Typography color="primary" variant="subtitle1" align={'left'}>
            <IntlMessages id="pages.orderSearchPage.hint" />
          </Typography>
        </Alert>

        <div className={classes.spacer} />

        <div className={classes.btnWrapper}>
          <CustomButton
            color="primary"
            variant="outlined"
            size="large"
            disabled={pristine || submitting}
            onClick={resetForm}
            type="button"
          >
            <IntlMessages id={'pages.orderSearchPage.button.reset'} />
          </CustomButton>
          <CustomButton color="danger" variant="contained" size="large" disabled={pristine || submitting} type="submit">
            <IntlMessages id={'pages.orderSearchPage.button.search'} />
          </CustomButton>
        </div>
      </div>
    </Form>
  );
};

const validate = (SearchOrderFormData: ISearchOrderFormData) => {
  const errors: any = {};
  if (
    SearchOrderFormData.idType === 'midocoID' &&
    SearchOrderFormData.crsBookingId &&
    isNaN(+SearchOrderFormData.crsBookingId)
  ) {
    errors.id = 'The OrderId must contains only numbers';
  }

  return errors;
};

export default reduxForm<ISearchOrderFormData>({
  form: 'searchOrderForm',
  validate,
  enableReinitialize: false,
  initialValues: {
    idType: 'bookingID',
  },
})(SearchOrderForm);
